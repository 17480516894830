import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';

const Header = () => {
  return (
    <header className="sticky-header">
      <div className="container">
        <NavLink to="/" className="logo">Niche Site Analytics</NavLink>
        <nav>
          <ul>
            <li><NavLink to="/" end>Home</NavLink></li>
            <li><NavLink to="/about">About</NavLink></li>
            <li><NavLink to="/tools">Tools</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
          </ul>
        </nav>
        <div className="header-auth-buttons">
          {/* Commented out login and signup buttons
          <button className="header-auth-button header-auth-button--login">Log In</button>
          <button className="header-auth-button header-auth-button--signup">Sign Up</button>
          */}
          <span className="header-auth-message">Login/Signup coming soon</span>
        </div>
      </div>
    </header>
  );
};

export default Header;