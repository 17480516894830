import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ScrollToTop from './components/ScrollToTop';

// Core pages
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Blog = lazy(() => import('./pages/Blog'));

// Tool pages
const Tools = lazy(() => import('./pages/Tools'));
const ImageResizer = lazy(() => import('./pages/tools/ImageResizer'));
const MetaTagGenerator = lazy(() => import('./pages/tools/MetaTagGenerator'));
const KeywordClustering = lazy(() => import('./pages/tools/KeywordClustering'));
const TwitterImageEditor = lazy(() => import('./pages/tools/TwitterImageEditor'));
const ContentIdeaGenerator = lazy(() => import('./pages/tools/ContentIdeaGenerator'));
const SchemaMarkupGenerator = lazy(() => import('./pages/tools/SchemaMarkupGenerator'));
const KeywordDifficultyAnalyzer = lazy(() => import('./pages/tools/KeywordDifficultyAnalyzer'));

// Legal and informational pages
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Sitemap = lazy(() => import('./pages/Sitemap'));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <main>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* Core routes */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />

              {/* Tool routes */}
              <Route path="/tools" element={<Tools />} />
              <Route path="/tools/image-resizer" element={<ImageResizer />} />
              <Route path="/tools/meta-tag-generator" element={<MetaTagGenerator />} />
              <Route path="/tools/keyword-clustering" element={<KeywordClustering />} />
              <Route path="/tools/twitter-image-editor" element={<TwitterImageEditor />} />
              <Route path="/tools/content-idea-generator" element={<ContentIdeaGenerator />} />
              <Route path="/tools/schema-markup-generator" element={<SchemaMarkupGenerator />} />
              <Route path="/tools/keyword-difficulty-analyzer" element={<KeywordDifficultyAnalyzer />} />

              {/* Legal and informational routes */}
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/sitemap" element={<Sitemap />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;